/* eslint-disable */
$(function() {

    /********** GLOBAL VAR **********/
    var heightWindows = document.documentElement.clientHeight,
        heightPage = $(document).height(),
        svgLine = $('.js-svg-anim').drawsvg(),
        svgLine2 = $('.js-svg-anim-2').drawsvg(),
        heightWindow = $(window).height(),
        scrollPos = 0,
        timer, timer2,
        flagDown = false,
        flagUp = false;

        if ($(".js-main-banner").length){
          var heightBanner = document.getElementsByClassName("js-main-banner")[0].getBoundingClientRect().height;
        }
    /********** END GLOBAL VAR **********/
    
    
    /********** SCROLL LINE ANIMATION **********/
    const heightScrollLine = 267;
    
    function getPerscentScroll(scroll) {
      let percent =  (100 * scroll ) /( heightPage - heightWindows );
    
      return Math.floor((heightScrollLine * percent) / 100);
    }
    /********** END SCROLL LINE ANIMATION **********/
    
    
    /********** SCROLL DIRECTION **********/
    function getDirectionScroll(scroll) {
      let direct = "down";
    
      (scroll > scrollPos) ? direct = "down" :  direct = "up";
      
      scrollPos = scroll;
    
      return direct;
    }
    
    /********** END DIRECTION **********/
    
    
    
    /********** SCROLL SVG LINE ANIMATION **********/
    function getPerscentDrtailBlock(scroll) {
      return (scroll/$(".block-detail").height());
    }
    /********** END SVG SCROLL LINE ANIMATION **********/
    
    
    
    /********** GALLERY SLIDER **********/
    $(".js-gallery").slick({
      infinite: true,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      focusOnSelect: true,
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
          <svg width="11" height="17" viewBox="0 0 11 17" class="svg-icon">\
              <use xlink:href="#svg-slider-arrow"></use>\
          </svg>\
      </button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="">\
          <svg width="11" height="17" viewBox="0 0 11 17" class="svg-icon">\
              <use xlink:href="#svg-slider-arrow"></use>\
          </svg>\
      </button>'
    });
    
    $(".js-gallery-big").slick({
      infinite: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      focusOnSelect: true,
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
          <div class="inner-block"><svg width="11" height="17" viewBox="0 0 11 17" class="svg-icon">\
              <use xlink:href="#svg-slider-arrow"></use>\
          </svg><div>\
      </button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="">\
      <div class="inner-block"><svg width="11" height="17" viewBox="0 0 11 17" class="svg-icon">\
              <use xlink:href="#svg-slider-arrow"></use>\
          </svg><div>\
      </button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            centerMode: true
          }
        }
      ]
    });
    
    $(".js-project-slider").slick({
      infinite: true,
      slidesToShow: 1,
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
          <div class="inner-block"><svg width="11" height="17" viewBox="0 0 11 17" class="svg-icon">\
              <use xlink:href="#svg-slider-arrow"></use>\
          </svg><div>\
      </button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="">\
      <div class="inner-block"><svg width="11" height="17" viewBox="0 0 11 17" class="svg-icon">\
              <use xlink:href="#svg-slider-arrow"></use>\
          </svg><div>\
      </button>'
    });
    /********** END GALLERY SLIDER **********/
    
    
    /********** VIDEO CONTROL **********/
    $(".js-video-control").on( "click", function() {
      $(this).addClass("is-hidden");
      $(this).next(".js-video").get(0).play();
    });
    /********** END VIDEO CONTROL **********/
    
    
    
    /********** ACCORDION **********/
    $(".js-accordion-control").on( "click", function() {
      $(this).toggleClass("is-active");
      $(this).next(".js-accordion-body").slideToggle(function() {});
    });
    
    $(".js-accordion-up").on( "click", function() {
      $(this).parents(".js-accordion-body").slideToggle(function() {});
      $(this).parents(".js-accordion").find(".js-accordion-control").toggleClass("is-active");
    });
    /********** END ACCORDION **********/
    if ($(".main-about").length) { 
      var topShip = $(".main-about").offset().top;
    }
    
    if ($(".js-sticky-container").length) { 
      var mainAboutTop = $(".js-sticky-container").offset().top;
    }

    if ($(window).width() > '960') {
      let widthItem = $(".js-sticky-container").width(),
      heightItem = $(".js-sticky-container").height();
      
      $(".js-sticky-container").height(heightItem)    
      $(".js-sticky-body").width(widthItem);
    }

    
    /********** SCROLL WINDOWS **********/
    $( window ).scroll(function() {
        let top = $(window).scrollTop();

        if ($(".js-sticky-body").length) {
          if ($(window).width() > '960') {
            let mainAboutOffset = (heightWindow - $(".js-sticky-body").height())/ 2
          
            if (top > (mainAboutTop - mainAboutOffset) ) {
              console.log(top , mainAboutTop , mainAboutOffset , mainAboutTop - mainAboutOffset , heightWindow);
              $(".js-sticky-body").addClass("is-fixed");
              $(".js-sticky-body").css('top' , mainAboutOffset +'px')
            } 
    
            if (top < (mainAboutTop - mainAboutOffset) ) {
              $(".js-sticky-body").removeClass("is-fixed");
              $(".js-sticky-body").css('top' , '0px')
            } 
    
            if (  (top + $(".js-sticky-body").height() + mainAboutOffset )
                  > ( $(".main-about").offset().top + $(".main-about").outerHeight() ) ) {
              
              $(".js-sticky-body").addClass("is-abs");
            }
    
            if (  (top + $(".js-sticky-body").height() + mainAboutOffset )
                  < ( $(".main-about").offset().top + $(".main-about").outerHeight() ) ) {
              
              $(".js-sticky-body").removeClass("is-abs");
            }
          }
        }


        if (getDirectionScroll(top) == "down") {
          $(".js-project-main").each(function( index , elem ) {
            let offsetImg = $(this).find(".js-project-middle-block").offset().top,
                itemHeight = $(elem).height(),
                itemtop = $(elem).offset().top,
                offsetValue = (heightWindow - $(elem).find(".js-sticky-project").outerHeight()) / 2;

            if ((top + heightWindow) > offsetImg) {
              //$(elem).find(".js-project-middle").css('transform', 'translateY(' + ((itemtop - top - offsetValue))+ 'px)');
              $(elem).find(".js-project-middle").css('transform', 'translateY(-' + (((top + heightWindow)-offsetImg)/3)+ 'px)');
            }
              /*let itemHeight = $(elem).height(),
                  itemtop = $(elem).offset().top,
                  offsetValue = (heightWindow - $(elem).find(".js-sticky-project").outerHeight()) / 2;

                  if (top > itemtop - offsetValue) {
                    $(elem).find(".js-project-middle").css('transform', 'translateY(' + ((itemtop - top - offsetValue))+ 'px)');
                  } */
          });

        } else if (getDirectionScroll(top) == "up") {
          $(".js-project-main").each(function( index , elem ) {
            let offsetImg = $(this).find(".js-project-middle-block").offset().top,
                itemHeight = $(elem).height(),
                itemtop = $(elem).offset().top,
                offsetValue = (heightWindow - $(elem).find(".js-sticky-project").outerHeight()) / 2;

            if ((top + heightWindow) > offsetImg) {
              //$(elem).find(".js-project-middle").css('transform', 'translateY(' + ((itemtop - top - offsetValue))+ 'px)');
              $(elem).find(".js-project-middle").css('transform', 'translateY(-' + (((top + heightWindow)-offsetImg)/3)+ 'px)');
            }
          });


            /*$(".js-project-main").each(function( index , elem ) {
              let itemHeight = $(elem).height(),
                  itemtop = $(elem).offset().top,
                  offsetValue = (heightWindow - $(elem).find(".js-sticky-project").outerHeight()) / 2;

                  if (top > itemtop - offsetValue) {
                    $(elem).find(".js-project-middle").css('transform', 'translateY(' + ((itemtop - top - offsetValue))+ 'px)');
                  } 
            });*/
          }
     
        $(".js-scroll-line").css('height', getPerscentScroll(top)+'px');
    
        svgLine.drawsvg('progress', getPerscentDrtailBlock(top));
        svgLine2.drawsvg('progress', getPerscentDrtailBlock(top));

        (top > 0) ? $(".js-main-banner").addClass("is-down") : $(".js-main-banner").removeClass("is-down");
    
    /*
        if ( ($("body").hasClass("index") ) && ($(window).width() > '1200') ) {
    
         if ((flagDown == false) && (heightBanner > top) && (getDirectionScroll(top) == "down") ) {
            clearTimeout(timer);
            timer = setTimeout(function() {

            flagDown = true;
            flagUp = false;
    
            $('html, body').stop(true, true).animate({
              scrollTop: heightBanner
            }, 1200, function(){
    
            $(".js-main-banner").addClass("is-down")

            }); 
            }, 50);

          } else if ((flagUp == false) 
          && (flagDown == true) 
          && (heightBanner > top) 
          && (getDirectionScroll(top) == "up") ) {
            clearTimeout(timer2);
            timer2 = setTimeout(function() {

              flagUp = true;
              flagDown = false;
      
              $('html, body').stop(true, true).animate({
                scrollTop: 0
              }, 1200, function(){
                $(".js-main-banner").removeClass("is-down")
              });
 
            }, 50);
          }
        }*/
    
    });
    /********** END SCROLL WINDOWS **********/
    
    
    
    
    
    
    
    
    
    /********** ANIMATION MAP PATH **********/
    
    
    /********** ANIMATION MAP PATH**********/
    
    
    
      if ($(window).width() > '959'){
        $(".js-sticky-offset").stick_in_parent({});
      }

      $(".js-sticky-project").each(function( index, elem ) {
        let offsetValue = (heightWindow - $(elem).outerHeight()) / 2;

        $(elem).data("height" , $(elem).outerHeight());

        $(elem).stick_in_parent({
          offset_top: offsetValue
        });
      });

      /*$(".js-sticky-project").stick_in_parent({
        offset_top: $(this).data("height")
      });*/
    


      $(".js-sticky-map").stick_in_parent({
        offset_top: 70
      });
    
      if ($(window).width() > '1200'){
        $(".js-sticky-aside").stick_in_parent({
          offset_top: 70
        });
      }
    
    
    
        $(".js-sticky").stick_in_parent({
         
        });
    
        $(".js-phone").mask("+7(___)___-__-__");
    
        /*FORM*/  
          $(".js-datepicker").datepicker({
            showOn: "button",
            buttonImage: "images/common/fe_calendar.png",
            buttonImageOnly: true,
            buttonText: "Select date",
            dateFormat: 'dd.mm.yy',
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            firstDay: 1,
            maxDate: '0',
            beforeShow:function(textbox, instance){
              $(this).parents(".form-main__date").append($('#ui-datepicker-div'));
            }
          }).mask("99.99.9999");
    
          $(".js-datepicker").on("blur", function () {
            var date = $(this).val(),
                dValue = new Date(),
                currentDate = dValue.getDate() + "." + ("0" + (dValue.getMonth() + 1)).slice(-2) + "." + dValue.getFullYear();
    
            $(".js-datepicker").parents(".js-datepicker-main").removeClass('is-active');
    
            var arrD = date.split(".");
            arrD[1] -= 1;
            var d = new Date(arrD[2], arrD[1], arrD[0]);
    
            if (d.getFullYear() == arrD[2] && d.getMonth() == arrD[1] && d.getDate() == arrD[0]) {
                return true;
            } else {
                $(this).val(currentDate);
                return false;
            }
        });

        $(".js-submit-ok").on( "click", function() {
          $(".js-popup-ok").addClass("is-active");

          return false
        });
    
        /*DINAMIC*/
        
        $('body').on( 'focus', '.js-form-input', function () { 
          $(this).addClass("is-active");
        });
    
        $('body').on( 'blur', '.js-form-input', function () { 
          let inputVal = $(this).val();
    
          if (inputVal.length != 0) {
            $(this).addClass("is-active")
          } else {
            $(this).removeClass("is-active")
          }
        });
    
        $('body').on( 'focus', '.js-datepicker ', function () { 
          $(this).addClass("is-active");
        });
    
        $('body').on( 'blur', '.js-datepicker ', function () { 
          let inputVal = $(this).val();
    
          if (inputVal.length != 0) {
            $(this).addClass("is-active")
          } else {
            $(this).removeClass("is-active")
          }
        });
        
    
        $(".js-select").styler();
    
        function blockHtmlLineExp(count) {
          let experienceBlock = '<div class="form-main__line form-main__line--post">\
          <div class="form-main__number">\
            '+(count+1)+'\
          </div>\
          <div class="form-main__line-body">\
          <div class="close js-row-delete">\
            <svg width="19" height="19" viewBox="0 0 19 19" class="svg-icon">\
              <use xlink:href="#svg-cross"></use>\
            </svg>\
          </div>\
            <div class="form-main__field col-66">\
              <div class="form-main__label">Должность *</div>\
              <div class="select">\
                <select class="js-select" name="" id="">\
                  <option value="">A1</option>\
                  <option value="">A2</option>\
                  <option value="">A3</option>\
                  <option value="">A4</option>\
                  <option value="">A5</option>\
                </select>\
              </div>\
            </div>\
            <div class="form-main__field col-66-480">\
              <div class="form-main__label">Тип судна *</div>\
              <div class="select">\
                <select class="js-select" name="" id="">\
                  <option value="">A1</option>\
                  <option value="">A2</option>\
                  <option value="">A3</option>\
                  <option value="">A4</option>\
                  <option value="">A5</option>\
                </select>\
              </div>\
            </div>\
            <div class="form-main__field col-33">\
              <div class="form-main__label">Месяцев работы *</div>\
              <input class="form-main__input js-form-input" type="text">\
            </div>\
          </div>\
        </div>\
          '
    
          return experienceBlock;
        }
    
        function blockHtmlLinePlace(count) {
          let experienceBlock = '<div class="form-main__line">\
          <div class="form-main__number">\
          '+(count+1)+'\
          </div>\
          <div class="form-main__line-body">\
          <div class="close js-row-delete">\
            <svg width="19" height="19" viewBox="0 0 19 19" class="svg-icon">\
              <use xlink:href="#svg-cross"></use>\
            </svg>\
          </div>\
            <div class="form-main__field col-45 col-33-640">\
              <div class="form-main__label">Судно *</div>\
              <input class="form-main__input js-form-input" type="text">\
            </div>\
            <div class="form-main__field col-55 col-33-640">\
              <div class="form-main__label">Тип судна *</div>\
              <div class="select">\
                <select class="js-select" name="" id="">\
                  <option value="">A1</option>\
                  <option value="">A2</option>\
                  <option value="">A3</option>\
                  <option value="">A4</option>\
                  <option value="">A5</option>\
                </select>\
              </div>\
            </div>\
            <div class="form-main__field col-45 col-33-640">\
              <div class="form-main__label">Флаг *</div>\
              <div class="select">\
                <select class="js-select" name="" id="">\
                  <option value="">A1</option>\
                  <option value="">A2</option>\
                  <option value="">A3</option>\
                  <option value="">A4</option>\
                  <option value="">A5</option>\
                </select>\
              </div>\
            </div>\
            <div class="form-main__field col-55 col-33-640">\
              <div class="form-main__label">Тип двигателя</div>\
              <div class="select">\
                <select class="js-select" name="" id="">\
                  <option value="">A1</option>\
                  <option value="">A2</option>\
                  <option value="">A3</option>\
                  <option value="">A4</option>\
                  <option value="">A5</option>\
                </select>\
              </div>\
            </div>\
            <div class="form-main__field col-100 col-66-640">\
              <div class="form-main__label">Судовладелец</div>\
              <input class="form-main__input js-form-input" type="text">\
            </div>\
            <div class="form-main__field col-100 col-50">\
              <div class="form-main__label">Должность *</div>\
              <div class="select">\
                <select class="js-select" name="" id="">\
                  <option value="">A1</option>\
                  <option value="">A2</option>\
                  <option value="">A3</option>\
                  <option value="">A4</option>\
                  <option value="">A5</option>\
                </select>\
              </div>\
            </div>\
            <div class="form-main__field col-45 col-25">\
              <div class="form-main__label">Дата начала</div>\
              <div class="form-main__date">\
                <input class="form-main__input js-datepicker" type="text">\
              </div>\
            </div>\
            <div class="form-main__field col-55 col-25">\
              <div class="form-main__label">Дата завершения</div>\
              <div class="form-main__date">\
                <input class="form-main__input js-datepicker" type="text">\
              </div>\
            </div>\
          </div>\
        </div>\
          '
    
          return experienceBlock;
        }
       
    
        $(".js-add-item").on( "click", function() {
          let blockHtml = $(this).data("item"),
              countItem = $(".js-main-list[data-item="+blockHtml+"]").find(".form-main__line").length;
    
          if (blockHtml == "experience") {
            $( ".js-main-list[data-item="+blockHtml+"]" ).append(blockHtmlLineExp(countItem));
            $(".js-select").styler(); 
          } else {
            $( ".js-main-list[data-item="+blockHtml+"]" ).append(blockHtmlLinePlace(countItem));
            $(".js-select").styler(); 
          }
        });
    
        $('body').on( 'click', '.js-row-delete', function () { 
          let mainBlock = $(this).parents(".js-main-list");
    
          $(this).parents(".form-main__line").remove();
    
          mainBlock.find(".form-main__line").each(function(index, elem) {
            $(elem).find(".form-main__number").text(index+1);
          });
        });
    
        /*FORM END */
    
        $(".js-hamburger").on( "click", function() {
            $(".js-menu").toggleClass("is-active");
        });
    
        $(".js-menu-close").on( "click", function() {
            $(".js-menu").toggleClass("is-active");
        });
    
          $(".js-dropdown-menu").on( "mouseenter", function() {
              $(".js-menu").addClass("is-hover");
          });
    
          $(".js-dropdown-menu").on( "mouseleave", function() {
            $(".js-menu").removeClass("is-hover");
        });
        
    
        $(".js-menu").swipe( {
            //Generic swipe handler for all directions
            swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
              if (direction == "left") {
                $(".js-menu").removeClass("is-active");
              }
            }
        });
    
    
    
    
        /*TAB fleet*/
    
        $(".js-tab-item-mobile").on( "click", function() {
          $(this).toggleClass("is-active");
    
          $(this).next(".js-tab-block").slideToggle(function() {});
        });
    
        $(".js-tab-item-up").on( "click", function() {
          let tabNumber = $(this).data("tab");
    
          $(this).parents(".js-tab-block").slideToggle(function() {});
          $(".js-tab-item-mobile[data-tab="+tabNumber+"]").toggleClass("is-active");
        });
    
        /*Переключение табов и появление соотвествующих кораблей*/
        let shipPoint = 0;
    
        if ($(".js-ship-point").length){
          shipPoint = $(window).width() 
          - document.querySelector(".js-ship-point").getBoundingClientRect().right 
          + (document.querySelector(".js-ship-point").getBoundingClientRect().width / 2);
        }
    
        function shipTransform(shipPoint) {
            $(".js-ship").each(function(index, elem) {
            $(elem).attr('data-left' , shipPoint - $(elem).width() / 2 );
            });
        };
    
        shipTransform(shipPoint);
    
       
        $(".js-ship.is-active").css("transform" , "translateX(-"+$(".js-ship.is-active").data("left")+"px)")
      
        $(".js-tab-item").on( "click", function() {
          let tabNumber = $(this).data("tab");
    
          $(".js-tab-item").removeClass("is-active");
          $(".js-tab-item[data-tab="+tabNumber+"]").addClass("is-active");
    
          $(".js-tab-block").removeClass("is-active");
          $(".js-tab-block[data-tab="+tabNumber+"]").addClass("is-active");
    
          let currentShip =  $(".js-ship.is-active").data("tab");
    
          $(".js-ship.is-active").addClass("is-back");
          $(".js-ship[data-tab="+tabNumber+"]").addClass("is-active").removeClass("is-back");
          $(".js-ship[data-tab="+tabNumber+"]").css("transform" , "translateX(-"+$(".js-ship[data-tab="+tabNumber+"]").data("left")+"px)")
    
          setTimeout(function () {
             $(".js-ship[data-tab="+currentShip+"]").removeClass("is-active").removeAttr( 'style' );
           }, 300); 
        });
    
    
    
        new WOW().init();
    
    
        /*POPUP*/
        $(".js-popup-open").on("click", function () {
            var popup = $(this).data('popup');
            $(".js-popup[data-popup=" + popup + "]").addClass('is-active');
          });
    
          $(".js-popup-close").on("click", function () {
            var popup = $(this).parents(".js-popup").data('popup');
    
            $(".js-popup[data-popup=" + popup + "]").removeClass('is-active');
          });
    
          $(".js-submit").on("click", function () {
              let popup = $(this).parents(".js-popup");
    
              popup.find(".js-form-hidden").each(function( index ) {
                $(this).css("display","none");
              });
              popup.find(".js-form-ok").addClass("is-active")
    
              return false;
          });
        /*POPUP END*/
    
    
          /*PEOPLE*/
    
          $(".js-people-avatar").attr('data-wow-offset',heightWindow/1.5);
          $(".js-people-body").attr('data-wow-offset',heightWindow/1.5);
          $(".js-wow-offset").attr('data-wow-offset',heightWindow/3);
    
    
    });
    